import { template as template_a41607f0c608436fb2f6f7ada6402916 } from "@ember/template-compiler";
const FKLabel = template_a41607f0c608436fb2f6f7ada6402916(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
